import React from "react";
import styles from "./Images.module.scss";
import GatsbyImage from "gatsby-image";
import generateFluid from "../../util/generateFluid";
import FadeUp from "../effects/FadeUp";
import Section from "../layout/Section";
import Container from "../layout/Container";
import Row from "../layout/Row";

const Images = ({image1, image2, caption, reducedSpacingBottom, theme}) => {
    const imageSizesHalf = "(min-width: 1680px) 839, (min-width: 600px) 36vw, 50vw";
    const imageSizesFull = "(min-width: 1680px) 595, (min-width: 600px) 50vw, 100vw";

    return image1 || image2 ? (
        <Section reducedSpacingBottom={reducedSpacingBottom} className={`${styles.images} ${image1 && image2 ? styles.imagesHasTwo : ""}`} theme={theme}>
            <Container>
                <Row>
                    <div className={`${styles.images__images} ${caption ? styles.images__imagesSpacingBelow : ""}`}>
                        {image1 && <FadeUp className={styles.image}><GatsbyImage fluid={generateFluid(image1, image2 ? imageSizesFull : imageSizesHalf)} /></FadeUp>}
                        {image2 && <FadeUp className={styles.image}><GatsbyImage fluid={generateFluid(image2, imageSizesHalf)} /></FadeUp>}
                    </div>
                </Row>
                {caption &&
                    <Row>
                        <div className={styles.images__caption}>
                            <span dangerouslySetInnerHTML={{__html: caption}} />
                        </div>
                    </Row>
                }
            </Container>
        </Section>
    ) : null;
};

export default Images;