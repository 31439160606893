import React from "react";
import {Link} from "gatsby";
import styles from "./Breadcrumbs.module.scss";
import getColors from "../../util/getColors";
import Container from "../layout/Container";
import Row from "../layout/Row";

const Breadcrumbs = ({breadcrumbs, theme}) => {
  let current = breadcrumbs;
  if(!breadcrumbs) return null;
  let crumbs = [];

  while(current !== null && current !== undefined) {
    if(crumbs.length) {
      crumbs.push(<li key={current.uri} className="breadcrumbs"><Link to={`/${current.uri}`}>{current.title}</Link></li>);
    } else {
      crumbs.push(<li key={current.uri} className="breadcrumbs">{current.title}</li>);
    }
    current = current.parent;
  }

  crumbs.push(<li key="home" className="breadcrumbs"><Link to={`/`}>Home</Link></li>);

  const themeColors = getColors(theme);

  return breadcrumbs ? (
    <div className={styles.breadcrumbs} style={themeColors}>
      <Container extended={true}>
        <Row>
          <div className={styles.breadcrumbs__content}>
            <ul>
              {crumbs.reverse()}
            </ul>
          </div>
        </Row>
      </Container>
    </div>
  ) : null;
};

export default Breadcrumbs;