import React from "react"
import { graphql } from "gatsby"
import Breadcrumbs from "../components/Breadcrumbs"
import SEO from "../components/seo";
import TextContent from "../components/TextContent";
import Hero from "../components/Hero";
import BlockQuote from "../components/BlockQuote";
import ComplexList from "../components/ComplexList";
import LinksList from "../components/LinksList";
import Accordion from "../components/Accordion";
import InlineCta from "../components/InlineCta";
import RelatedContent from "../components/RelatedContent";
import LeftNav from "../components/LeftNav";
import Images from "../components/Images";
import Cta from "../components/Cta";
import BodySections from "../components/layout/BodySections";
import Footer from "../components/Footer";
import HubspotForm from "../components/HubspotForm";

const standardPage = (props) => {

    const craftData = props.data.craft.entry;

    const {title, darkMode, relatedPages, standardContent, standardHero, showSubmenu, callToAction} = craftData;

    const theme = darkMode ? "dark-extra-dark" : null;
    
    const heroData = standardHero && standardHero.length ? standardHero[0] : {};
    const {displayTitle} = heroData;
    const heroBackgroundImage = heroData && heroData.backgroundImage && heroData.backgroundImage.length ? heroData.backgroundImage[0] : null;
    const heroTheme = heroData && heroData.backgroundImage && heroData.backgroundImage[0] ? "dark" : heroData.theme === "light" ? "cream" : heroData.theme;

    const {title: leftNavTitle, uri: leftNavUri} = craftData.parent || [];

    const leftNavItems = craftData.parent ? craftData.parent.children.map(child => {
        return {
            url: child.uri,
            text: child.title,
            externalLink: child.externalLink
        };
    }) : null;

    const relatedItems = relatedPages.map(item => {
        return {
            title: item.relatedTitle,
            content: item.relatedContent,
            url: item.relatedLink.url,
            linkText: item.relatedLink.text,
            external: item.relatedLink.type === "url",
            target: item.relatedLink.target,
            image: item.image ? item.image[0] : null
        }
    });

    const getComponent = (data, index, followingComponentType) => {
        const followsImageComponent = followingComponentType === "images";

        switch(data.typeHandle) {
            case "textContent":
                return <TextContent
                    content={data.textContent}
                    reducedSpacingBottom={followsImageComponent}
                    theme={theme ? theme : "light"}
                    key={data.id}
                />
            case "blockQuote":
                return <BlockQuote
                    quote={data.quote}
                    attributionName={data.attributionLine1}
                    attributionTitle={data.attributionLine2}
                    attributionImage={data.attributionImage ? data.attributionImage[0] : null}
                    reducedSpacingBottom={followsImageComponent}
                    theme={theme ? theme : "light"}
                    key={data.id}
                />
            case "complexList":
                const complexListItems = data.rows.map(item => {
                    return {
                        icon: item.icon,
                        title: item.listTitle,
                        content: item.listContent,
                        image: item.image ? item.image[0] : null,
                        linkStyle: item.linkStyle,
                        link: item.listLink
                    }
                });

                return <ComplexList
                    listItems={complexListItems}
                    reducedSpacingBottom={followsImageComponent}
                    theme={theme ? theme : "light"}
                    key={data.id}
                />
            case "linksList":
                const linksListItems = data.links.map(item => {
                    return {
                        icon: item.icon,
                        link: item.listLink
                    }
                });

                return <LinksList
                    title={data.linksTitle}
                    content={data.linksContent}
                    listItems={linksListItems}
                    reducedSpacingBottom={followsImageComponent}
                    theme={theme ? theme : "light"}
                    key={data.id}
                />
            case "accordion":
                const accordionListItems = data.accordionItems.map(item => {
                    return {
                        title: item.accordionItemTitle,
                        content: item.accordionContent
                    }
                });

                return <Accordion
                    title={data.accordionTitle}
                    items={accordionListItems}
                    reducedSpacingBottom={followsImageComponent}
                    theme={theme ? theme : "light"}
                    key={data.id}
                />
            case "inlineCta":
                return <InlineCta
                    icon={data.icon}
                    title={data.ctaTitle}
                    content={data.ctaContent}
                    link={data.ctaLink}
                    reducedSpacingBottom={followsImageComponent}
                    theme={theme ? theme : "light"}
                    key={data.id}
                />
            case "images":
                return <Images
                    image1={data.images ? data.images[0] : null}
                    image2={data.images && data.images.length > 1 ? data.images[1] : null}
                    caption={data.caption}
                    reducedSpacingBottom={index !== standardContent.length - 1} // when not the last component in body
                    theme={theme ? theme : "light"}
                    key={data.id}
                />
            case "hubspotForm":
                return <HubspotForm key={data.id} {...data} theme="light" />
            default:
                return null;
        };
    };

    const bodyComponents = standardContent.map((content, i) => {
        const followingComponentType = standardContent[i+1] && standardContent[i+1].typeHandle;
        const component = getComponent(content, i, followingComponentType);
        return component;
    });

    const ctaData = callToAction && callToAction.length ? callToAction[0] : {};
    const {ctaTitle: ctaText, primaryLink: ctaLink1, secondaryLink: ctaLink2} = ctaData;

    return (<>
        <SEO title={title || ""} seomatic={craftData.seomatic} />
        <Hero
            title={displayTitle}
            bgImage={heroBackgroundImage}
            useBgPattern={!heroBackgroundImage && (theme !== "dark-extra-dark")}
            theme={theme === "dark-extra-dark" ? theme : heroTheme}
        />
        <Breadcrumbs
            breadcrumbs={props.pageContext.breadcrumbs}
            theme={theme ? theme : "light"}
        />
        <BodySections>
            {leftNavTitle !== null && leftNavItems !== null && showSubmenu ? (
                <LeftNav
                    parentTitle={leftNavTitle}
                    parentUri={leftNavUri}
                    items={leftNavItems}
                    theme={theme ? theme : "light"}
                />
            ) : null}
            {bodyComponents}
            <RelatedContent
                items={relatedItems}
                theme={theme ? theme : "light"}
            />
        </BodySections>
        <Cta text={ctaText} link1={ctaLink1} link2={ctaLink2} theme="brightscan-dark" />
        <Footer
            theme="dark"
        />
    </>);
};

export const pageQuery = graphql`
    query standardPage($id: CRAFT_QueryArgument!) {
        craft {
            entry(id:[$id]) {
                id
                title
                seomatic(asArray:true) {
                    metaTitleContainer
                    metaTagContainer
                    metaLinkContainer
                    metaScriptContainer
                    metaJsonLdContainer
                }
                parent {
                    title
                    uri
                    children {
                        uri
                        title
                        ...on CRAFT_pages_externalLink_Entry {
                            externalLink {
                                url
                            }
                        }
                    }
                }
                ...on CRAFT_pages_standardPage_Entry {
                    darkMode
                    standardHero {
                        ...on CRAFT_standardHero_BlockType {
                            displayTitle
                            theme
                            backgroundImage {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "heroImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "heroImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                        }
                    }
                    showSubmenu
                    standardContent {
                        ...on CRAFT_standardContent_textContent_BlockType {
                            typeHandle
                            textContent
                            id
                        }
                        ...on CRAFT_standardContent_blockQuote_BlockType {
                            typeHandle
                            quote
                            attributionLine1
                            attributionLine2
                            attributionImage {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "blockQuoteImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "blockQuoteImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                            id
                        }
                        ...on CRAFT_standardContent_accordion_BlockType {
                            typeHandle
                            accordionTitle
                            accordionItems {
                                ...on CRAFT_accordionItems_BlockType {
                                    accordionItemTitle
                                    accordionContent
                                }
                            }
                            afterContent
                            id
                        }
                        ...on CRAFT_standardContent_complexList_BlockType {
                            typeHandle
                            rows {
                                ...on CRAFT_rows_BlockType {
                                    icon {
                                        url
                                        contents
                                    }
                                    listTitle
                                    listContent
                                    linkStyle
                                    listLink {
                                        type
                                        target
                                        url
                                        customText
                                        text
                                    }
                                    image {
                                        lqPlaceholder
                                        width
                                        height
                                        responsiveImage(transform: "complexListImage") {
                                            width
                                            height
                                            url
                                        }
                                        responsiveImageWebp: responsiveImage(transform: "complexListImageWebp") {
                                            width
                                            height
                                            url
                                        }
                                    }
                                }
                            }
                            id
                        }
                        ...on CRAFT_standardContent_linksList_BlockType {
                            typeHandle
                            linksTitle
                            linksContent
                            links {
                                ...on CRAFT_links_BlockType {
                                    icon {
                                        url
                                        contents
                                    }
                                    listLink {
                                        type
                                        target
                                        url
                                        customText
                                        text
                                    }
                                }
                            }
                            id
                        }
                        ...on CRAFT_standardContent_inlineCta_BlockType {
                            typeHandle
                            icon {
                                url
                                contents
                            }
                            ctaTitle
                            ctaContent
                            ctaLink {
                                type
                                target
                                url
                                customText
                                text
                            }
                            id
                        }
                        ...on CRAFT_standardContent_images_BlockType {
                            typeHandle
                            images {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "imagesImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "imagesImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                            caption
                            id
                        }
                        ...on CRAFT_standardContent_hubspotForm_BlockType {
                            typeHandle
                            portalId
                            formId
                            id
                        }
                    }
                    relatedPages {
                        ...on CRAFT_relatedPages_BlockType {
                            relatedTitle
                            image {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "relatedPagesImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "relatedPagesImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                            relatedContent
                            relatedLink {
                                type
                                url
                                target
                                text
                            }
                        }
                    }
                    callToAction {
                        ...on CRAFT_callToAction_BlockType {
                            ctaTitle
                            primaryLink {
                                type
                                url
                                text
                                target
                            }
                            secondaryLink {
                                type
                                url
                                text
                                target
                            }
                        }
                    }
                }
            }
        }
    }

`


export default standardPage;