import React, {useState, useRef, useEffect, useCallback} from "react";
import styles from "./AccordionItem.module.scss";
import getColors from "../../../util/getColors";
import Chevron from "../../../svgs/Chevron";

const AccordionItem = ({title, content, theme}) => {
    const [open, setOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);

    const contentContainer = useRef();

    const themeColors = getColors(theme);

    const updateScrollHeight = useCallback(() => {
        const contentScrollHeight = contentContainer.current.scrollHeight;
        setContentHeight(contentScrollHeight);
    },[]);

    useEffect(() => {
        updateScrollHeight();
        window.addEventListener("resize",updateScrollHeight);

        return (() => {
            window.removeEventListener("resize",updateScrollHeight);
        });
    }, [updateScrollHeight]);

    const handleClick = (e) => {
        e.preventDefault();
        updateScrollHeight();
        setOpen(!open);
    };

    return (title || content) && (
        <div
            className={`${styles.listItem} ${open ? styles.listItemOpen : ""}`}
            style={themeColors}
        >
            <button
                className={styles.listItem__title}
                onClick={handleClick}
            >
                {title && <h5>{title}<span className={styles.linkSvg}>&nbsp;<Chevron /></span></h5>}
            </button>
            <div
                style={{"height": open ? `${contentHeight}px` : 0}}
                className={styles.listItem__content}
            >
                <span
                    dangerouslySetInnerHTML={{__html: content}}
                    className={styles.listItem__content__inner}
                    ref={contentContainer}
                />
            </div>
        </div>
    );
};

export default AccordionItem;