import React from "react";

const QuoteMark = () => {
    return (
        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="quote-mark-icon">
            <path d="M7.72363 9.59375L7.46387 9.18555C7.63704 8.51758 7.84115 7.80632 8.07617 7.05176C8.32357 6.2972 8.58952 5.53646 8.87402 4.76953C9.15853 4.0026 9.46159 3.24186 9.7832 2.4873C10.1048 1.72038 10.4202 0.99056 10.7295 0.297852H13.2715C13.0859 1.05241 12.9004 1.83789 12.7148 2.6543C12.5417 3.4707 12.3747 4.28711 12.2139 5.10352C12.0531 5.90755 11.9046 6.69303 11.7686 7.45996C11.6325 8.22689 11.515 8.93815 11.416 9.59375H7.72363ZM0.580078 9.59375L0.320312 9.18555C0.49349 8.51758 0.697591 7.80632 0.932617 7.05176C1.18001 6.2972 1.44596 5.53646 1.73047 4.76953C2.01497 4.0026 2.31803 3.24186 2.63965 2.4873C2.96126 1.72038 3.27669 0.99056 3.58594 0.297852H6.12793C5.94238 1.05241 5.75684 1.83789 5.57129 2.6543C5.39811 3.4707 5.23112 4.28711 5.07031 5.10352C4.90951 5.90755 4.76107 6.69303 4.625 7.45996C4.48893 8.22689 4.37142 8.93815 4.27246 9.59375H0.580078Z"/>
        </svg>        
    );
};

export default QuoteMark;