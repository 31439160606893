import React from "react";
import styles from "./Blockquote.module.scss";
import QuoteMark from "../../svgs/QuoteMark";
import GatsbyImage from "gatsby-image";
import generateFluid from "../../util/generateFluid";
import FadeUp from "../effects/FadeUp";
import Section from "../layout/Section";
import Row from "../layout/Row";
import Container from "../layout/Container";

const BlockQuote = ({quote, attributionName, attributionTitle, attributionImage, reducedSpacingBottom, theme}) => {
    return (quote || attributionName || attributionTitle) && (
        <Section reducedSpacingBottom={reducedSpacingBottom} className={styles.blockquote} theme={theme}>
            <Container>
                <Row>
                    <FadeUp className={styles.blockquote__content}>
                        {quote && <div className={styles.blockquote__content__quote}>
                            <QuoteMark /><span dangerouslySetInnerHTML={{__html: quote}} /><QuoteMark />
                        </div>}
                        {attributionName || attributionTitle || attributionImage ?
                            <div className={styles.blockquote__content__attribution}>
                                {attributionImage &&
                                    <GatsbyImage
                                        fluid={generateFluid(attributionImage, "(min-width: 600px) 54px, 45px")}
                                        className={styles.blockquote__content__attribution__image}
                                    />
                                }
                                <div className={styles.blockquote__content__attribution__details}>
                                    {attributionName && 
                                        <div>
                                            <p className="bold">{attributionName}</p>
                                        </div> 
                                    }
                                    {attributionTitle &&
                                        <div>
                                            <p>{attributionTitle}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        : null}
                    </FadeUp>
                </Row>
            </Container>
        </Section>
    );
};

export default BlockQuote;