import React from "react";
import styles from "./Accordion.module.scss";
import AccordionItem from "./AccordionItem";
import Section from "../layout/Section";
import Container from "../layout/Container";
import Row from "../layout/Row/Row";

const Accordion = ({title, items, afterContent, reducedSpacingBottom, theme}) => {
    const list = items && items.length ? items.map((item,i) => {
        return (
            <AccordionItem
                title={item.title}
                content={item.content}
                key={i}
            />
        );
    }) : null;

    return (title || afterContent || items && items.length) ? (
        <Section className={styles.accordion} reducedSpacingBottom={reducedSpacingBottom} theme={theme}>
            <Container>
                <Row>
                    <div className={styles.accordion__content}>
                        {title && <h3>{title}</h3>}
                        <div className={`${styles.accordion__content__list} ${afterContent ? styles.accordion__content__listSpacingAfter : ""}`}>
                            {list}
                        </div>
                        {afterContent && <div className={styles.accordion__contentAfter}>
                            <span dangerouslySetInnerHTML={{__html: afterContent}} />
                        </div>}
                    </div>
                </Row>
            </Container>
        </Section>
    ) : null;
};

export default Accordion;