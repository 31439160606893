import React from "react";
import Form from "react-hubspot-form";
import styles from "./HubspotForm.module.scss";
import Section from "../layout/Section";
import Container from "../layout/Container";
import Row from "../layout/Row";

const HubspotForm = ({portalId, formId, theme}) => {
  return (
    <Section className={styles.hubspotForm} theme={theme}>
        <Container>
            <Row>
                <div className={styles.hubspotForm__content}>
                  <Form portalId={portalId} formId={formId}/>
                </div>
            </Row>
        </Container>
    </Section>
  );
}

export default HubspotForm;