import React from "react";

const Arrow = () => {
    return (
        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow-icon">
            <path d="M12.7808 5.68865L12.8691 5.60026L12.7808 5.51187L8.1805 0.911612L8.09211 0.823223L8.00372 0.911612L7.3302 1.58514L7.24215 1.67318L7.32985 1.76156L10.5333 4.99032H1H0.875V5.11532V6.08519L0.875 6.21019H1L10.5333 6.21019L7.32985 9.43895L7.24215 9.52734L7.3302 9.61538L8.00372 10.2889L8.09211 10.3773L8.1805 10.2889L12.7808 5.68865Z" strokeWidth="0.25"/>
        </svg>
    );
};

export default Arrow;