import React, {useState, useEffect, useRef} from "react";
import styles from "./LeftNav.module.scss";
import LinkTo from "../elements/LinkTo";
import Arrow from "../../svgs/Arrow";
import getColors from "../../util/getColors";
import Chevron from "../../svgs/Chevron";
import Container from "../layout/Container";
import { Link } from "gatsby";

const LeftNav = ({parentTitle, parentUri, items, theme}) => {
    const themeColors = getColors(theme);

    const itemsContainerRef = useRef();

    const [open, setOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);

    useEffect(() => {
        handleResize();

        window.addEventListener("resize", handleResize);

        return (() => {
            window.removeEventListener("resize", handleResize);
        });
    }, []);

    const handleClick = (e) => {
        e.preventDefault();
        const contentScrollHeight = itemsContainerRef && itemsContainerRef.current ? itemsContainerRef.current.scrollHeight : 0;
        setContentHeight(contentScrollHeight);
        setOpen(!open);
    }

    const handleResize = () => {
        const contentScrollHeight = itemsContainerRef && itemsContainerRef.current ? itemsContainerRef.current.scrollHeight : 0;
        setContentHeight(contentScrollHeight);
    }

    const navItems = items && items.length ? items.map((item,i) => {
        return (
            item && item.url ? (
                <li key={i}>
                    <LinkTo url={item.externalLink ? item.externalLink.url : item.url} external={item.externalLink} activeClassName={styles.active}>
                        {item.text}<span>&nbsp;<Arrow /></span>
                    </LinkTo>
                </li>
            ) : null
        );
    }) : null;

    return items ? (
        <Container extended={true} className={styles.leftNav} style={themeColors}>
            <div className={styles.leftNav__inner}>
                <div className={styles.showMobile}>
                    <button className={`${styles.heading} ${open ? styles.headingOpen : ""}`} onClick={handleClick} aria-label="Open sub-menu">
                        <p className="lead bold">Explore this section</p>
                        <div><Chevron /></div>
                    </button>
                    <div className={styles.items} ref={itemsContainerRef} style={{height: open ? `${contentHeight}px` : 0}}>
                        {parentTitle && parentUri &&
                            <Link to={parentUri} className={`lead bold ${styles.title}`}>{parentTitle}</Link>
                        }
                        {items &&
                            <ul className="lead">
                                {navItems}
                            </ul>
                        }
                    </div>
                </div>
                <div className={styles.showDesktop}>
                    <p className="meta-sub">In this section</p>
                    {parentTitle && parentUri &&
                        <Link to={parentUri} className={`lead bold ${styles.title}`}>{parentTitle}</Link>
                    }
                    {items &&
                        <ul>
                            {navItems}
                        </ul>
                    }
                </div>
            </div>
        </Container>
    ) : null;
};

export default LeftNav;