import React from "react";
import styles from "./LinksList.module.scss";
import LinkTo from "../elements/LinkTo";
import Section from "../layout/Section";
import Container from "../layout/Container";
import Row from "../layout/Row";

const LinksList = ({title, content, listItems, reducedSpacingBottom, theme}) => {

    const list = listItems && listItems.length ? listItems.map((item, i) => {
        const {url, target, text} = item.link;
        return (
            url ? (
                <div className={styles.list__item} key={i}>
                    <LinkTo url={url} external={target === "_blank"} target={target}>
                        {item.icon.length > 0 && <span dangerouslySetInnerHTML={{__html: item.icon[0].contents}} className={styles.list__item__iconWrapper} />} {text}
                    </LinkTo>
                </div>
            ) : null
        );
    }) : null;

    return title || content || listItems ? (
        <Section className={styles.linksList} reducedSpacingBottom={reducedSpacingBottom} theme={theme}>
            <Container>
                <Row>
                    <div className={styles.linksList__content}>
                        <Row className={styles.listWrapper}>
                            <div>
                                {title ? <h4>{title}</h4> : null}
                                {content ?
                                    <div className={styles.list__content}>
                                        <span dangerouslySetInnerHTML={{__html: content}} />
                                    </div>
                                : null}
                                {list ?
                                    <div className={styles.list}>
                                        {list}
                                    </div>
                                : null}
                            </div>
                        </Row>
                    </div>
                </Row>
            </Container>
        </Section>
    ) : null;
};

export default LinksList;