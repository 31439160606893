import React from "react";
import styles from "./TextContent.module.scss";
import Row from "../layout/Row";
import Container from "../layout/Container";
import Section from "../layout/Section";

const TextContent = ({content, reducedSpacingBottom, theme}) => {
    return content && (
        <Section className={styles.textContent} reducedSpacingBottom={reducedSpacingBottom} theme={theme}>
            <Container>
                <Row>
                    <div className={styles.textContent__content}>
                        <span dangerouslySetInnerHTML={{__html: content}} />
                    </div>
                </Row>
            </Container>
        </Section>
    );
};

export default TextContent;