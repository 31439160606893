import React, {Fragment} from "react";
import styles from "./RelatedContent.module.scss";
import Button from "../elements/Button";
import LinkTo from "../elements/LinkTo";
import GatsbyImage from "gatsby-image";
import generateFluid from "../../util/generateFluid";
import FadeUp from "../effects/FadeUp";
import Row from "../layout/Row";
import Container from "../layout/Container";
import Section from "../layout/Section";

const RelatedContent = ({items, theme}) => {
    const list = items && items.length ? items.map((item,i) => {
        const inner = (
            <>
                {item.image ?
                    <div className={styles.image}>
                        {item.url ? (
                            <LinkTo url={item.url} external={item.external} target={item.target}>
                                <GatsbyImage fluid={generateFluid(item.image, "(min-width: 1680px) 433, (min-width: 600px) 26vw, 103vw")} />
                            </LinkTo>
                        ) : (
                            <GatsbyImage fluid={generateFluid(item.image, "(min-width: 1680px) 433, (min-width: 600px) 26vw, 103vw")} />
                        )}
                    </div>
                : null}
                <div className={styles.text}>
                    {item.title ? (
                        item.url ? (
                            <h5><LinkTo url={item.url} external={item.external} target={item.target} fake={!item.image} className={styles.title}>{item.title}</LinkTo></h5>
                         ) : (
                            <h5>{item.title}</h5>
                         )
                    ) : null}
                    {item.content &&
                        <div className={`${styles.content} ${item.url ? styles.contentHasMargin : ""}`}>
                            <span dangerouslySetInnerHTML={{__html: item.content}} />
                        </div>
                    }
                    {item && item.url ? <Button url={item.url} external={item.external} target={item.target} text={item.linkText} simpleStyle={true} fake={!item.image} /> : null}
                </div>
            </>
        );
        return (
            <Fragment key={i}>
                <FadeUp delay={`${i * 0.15}s`} disableDelayMobile={true} className={`${styles.item} ${!item.image ? styles.itemBorderStyle : ""}`}>
                    {item.url && !item.image ? (
                        <LinkTo
                            url={item.url}
                            external={item.external}
                            target={item.target}
                            className={`${styles.item__inner} ${styles.item__innerButton}`}
                        >
                            {inner}
                        </LinkTo>
                    ) : (
                        <div className={styles.item__inner}>
                            {inner}
                        </div>
                    )}
                </FadeUp>
                {i !== items.length - 1 ? (
                    <div>
                    </div>
                ) : null}
            </Fragment>
        );
    }) : null;

    return list ? (
        <Section className={styles.relatedContent} theme={theme}>
            <Container>
                <Row>
                    <div className={styles.relatedContent__mainCol}>
                        <h4 className={styles.title}>Related</h4>
                        <Row className={styles.itemsWrapper}>
                            {list}
                        </Row>
                    </div>
                </Row>
            </Container>
        </Section>
    ) : null;
};

export default RelatedContent;