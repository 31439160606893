import React from "react";
import styles from "./BodySections.module.scss";

const BodySections = ({children}) => {
    return (
        <div className={styles.bodySections}>
            {children}
        </div>
    );
};

export default BodySections;