import React from "react"
import styles from "./InlineCta.module.scss"
import Button from "../elements/Button"
import Container from "../layout/Container"
import Row from "../layout/Row"
import Section from "../layout/Section"

const InlineCta = ({
    icon,
    title,
    content,
    link,
    reducedSpacingBottom,
    theme,
}) => {
    return title || content || link.url ? (
        <Section
            className={styles.inlineCta}
            reducedSpacingBottom={reducedSpacingBottom}
            theme={theme}
        >
            <Container>
                <Row>
                    <div className={styles.inlineCta__wrapper}>
                        <Row className={styles.inlineCta__wrapper__cta}>
                            {icon && icon.length ? (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: icon[0].contents,
                                    }}
                                />
                            ) : null}
                            {title && <h4>{title}</h4>}
                            {content && (
                                <div
                                    className={
                                        styles.inlineCta__wrapper__cta__content
                                    }
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: content,
                                        }}
                                    />
                                </div>
                            )}
                            {link && link.url && (
                                <Button
                                    url={link.url}
                                    text={link.text}
                                    external={link.target === "_blank"}
                                    simpleStyle={true}
                                />
                            )}
                        </Row>
                    </div>
                </Row>
            </Container>
        </Section>
    ) : null
}

export default InlineCta
